import { StandaloneSearch } from 'base/components/Search';
import { useRouter } from 'next/router';

export const HantStandaloneSearch: typeof StandaloneSearch = ({ headline, ...props }) => {
  const router = useRouter();

  if (router?.pathname?.includes('/authors')) {
    headline = <h1>Artiklar av ${props.query}</h1>;
  }

  return <StandaloneSearch headline={headline} {...props} />;
};
