/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "item_caption_variant_horizontal": "order-2 capitalize",
  "item_caption_variant_vertical": "hidden",
  "item_caption_colors_default": "text-gray-600",
  "item_caption_size_horizontal": "text-body-xs",
  "item_caption_size_vertical": "",
  "item_description_variant_horizontal": "order-3",
  "item_description_variant_vertical": "",
  "item_description_colors_default": "",
  "item_description_size_horizontal": "!text-body-sm !mt-1",
  "item_description_size_vertical": "",
  "item_group_variant_horizontal": "",
  "item_group_variant_vertical": "",
  "item_group_colors_default": "",
  "item_group_size_horizontal": "",
  "item_group_size_vertical": "",
  "item_headline_variant_horizontal": "order-1",
  "item_headline_variant_vertical": "",
  "item_headline_colors_default": "",
  "item_headline_size_horizontal": "!text-headline-sm sm:!text-headline-md",
  "item_headline_size_vertical": "",
  "item_image_variant_horizontal": "",
  "item_image_variant_vertical": "",
  "item_image_colors_default": "",
  "item_image_size_horizontal": "",
  "item_image_size_vertical": "",
  "item_variant_horizontal": "",
  "item_variant_vertical": "",
  "item_colors_default": "",
  "item_size_horizontal": "gap-3 sm:gap-6",
  "item_size_vertical": "",
  "button_colors_default": "",
  "button_size_default": "",
  "button_variant_default": "",
  "caption_colors_default": "",
  "caption_size_default": "-mb-2",
  "caption_variant_default": "",
  "footer_colors_default": "",
  "footer_size_default": "",
  "footer_variant_default": "",
  "headline_colors_default": "[&_em]:text-gray-500",
  "headline_size_default": "",
  "headline_variant_default": "",
  "colors_default": "",
  "size_default": "py-6 gap-y-6",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Search");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;