/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "image": {
    "colors_default": "",
    "size_default": "aspect-[2/1]",
    "variant_default": "relative block object-cover"
  },
  "caption_colors_default": "text-primary-700",
  "caption_size_horizontal": "text-headline-xs sm:container-col-lg-7:text-preamble-md",
  "caption_size_vertical": "text-headline-xs sm:container-col-lg-7:text-preamble-md",
  "caption_variant_horizontal": "uppercase",
  "caption_variant_vertical": "uppercase",
  "description_colors_default": "text-black",
  "description_size_horizontal": "text-body-xs sm:container-col-lg-7:text-body sm:container-col-lg-7:mt-3",
  "description_size_vertical": "text-body-sm",
  "description_variant_horizontal": "break-words",
  "description_variant_vertical": "break-words",
  "footer_colors_default": "",
  "footer_size_horizontal": "max-w-full",
  "footer_size_vertical": "max-w-full",
  "footer_variant_horizontal": "col-span-full overflow-hidden",
  "footer_variant_vertical": "col-span-full",
  "group_colors_default": "",
  "group_size_horizontal": "gap-0.5",
  "group_size_vertical": "px-1.5 py-2 container-col-sm-8:px-3 container-col-sm-8:py-4 sm:px-2 sm:py-4 sm:container-col-md-8:p-6 group-[&[data-title-position]]/box:py-0 gap-y-2",
  "group_variant_horizontal": "flex flex-col min-w-[0]",
  "group_variant_vertical": "flex flex-col min-w-[0]",
  "header_colors_default": "",
  "header_size_horizontal": "max-w-full",
  "header_size_vertical": "max-w-full",
  "header_variant_horizontal": "col-span-2 overflow-hidden",
  "header_variant_vertical": "col-span-full uppercase flex flex-row items-center overflow-hidden absolute top-0 left-0 z-1",
  "headline_colors_default": "text-black [&_*]:text-black",
  "headline_size_horizontal": "text-headline-sm sm:container-col-lg-7:text-headline-lg",
  "headline_size_vertical": "text-headline-sm container-col-sm-8:text-headline-lg sm:text-headline-lg sm:container-col-lg-8:text-headline-xl",
  "headline_variant_horizontal": "break-words",
  "headline_variant_vertical": "",
  "colors_default": "text-black bg-white data-[index=\"1\"]:bg-primary-100",
  "size_horizontal": "w-full gap-3 grid-cols-2",
  "size_vertical": "w-full auto-rows-min group-[&[data-title-position=titleLeftSideOfImage]]/box:grid-cols-[53%_auto] group-[&[data-title-position=titleRightSideOfImage]]/box:grid-cols-[auto_53%]",
  "variant_horizontal": "grid container-inline",
  "variant_vertical": "grid container-inline relative group-[&[data-title-position=titleRightSideOfImage]]/box:items-center group-[&[data-title-position=titleLeftSideOfImage]]/box:[&>a]:order-2 group-[&[data-title-position=titleLeftSideOfImage]]/box:items-center"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleTeaserDefault");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;